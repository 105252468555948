import { ContextService } from 'src/app/shared/services/context.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { SelectCompanyComponent } from 'src/app/shared/components/dialogs/select-company/select-company.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { LayoutConfigurationDataService } from 'src/app/shared/services/layout-configuration-data.service';

@Component({
    selector: 'app-deactivated-account-subscription',
    templateUrl: './deactivated-account-subscription.component.html',
    styleUrl: './deactivated-account-subscription.component.scss'
})
export class DeactivatedAccountSubscriptionComponent implements OnInit {

    constructor(
        public dialog: MatDialog,
        private router: Router,
        public contextService: ContextService,
        private spinner: NgxSpinnerService,
    ) { }

    year: number = new Date().getFullYear();
    employeeCompanies: number = 0;
    selectedCompanyName: string='';
    
    ngOnInit(): void {
        this.employeeCompanies = this.contextService.employeeCompanies$.getValue().length;
        this.selectedCompanyName = this.contextService.companyName$.value;

    }

    contactSupport() {
        window.open(
            'mailto:support@truckincentral.com',
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    async selectCompanyDialog() {
        let dialogRef = this.dialog.open(SelectCompanyComponent, {
            disableClose: true,
            autoFocus: false,
            width: '35rem',
            data: {
                closeButton: true,
            },
            // height: '90rem'
        });
        let result = await lastValueFrom(dialogRef.afterClosed());
        if (result == 'close') {
           return;
        }
        this.spinner.show();
        await this.contextService.init();
        this.contextService.isAccountActive$.next(true);
        this.router.navigateByUrl('home');
        setTimeout(() => {
            this.spinner.hide();
        });
    }

}
