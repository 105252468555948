<div class="page-container">
    <div class="page-section">
        <div class="logo">
            <!-- <img src="assets/images/site-logo.svg"> -->
        </div>
        <div class="body">
            <h3 class="heading-3 bold">{{"ALMOST_THERE_JUST_ONE_MORE_STEP" |translate}}</h3>
            <p class="heading-5">
                {{"THANK_YOU_FOR_COMPLETING_THE_INITIAL_SETUP" |translate}}
            </p>
            <h5 class="heading-5 bold mt-4">{{"SPECIAL_OFFER" |translate}}</h5>
            <p class="heading-5">
                Enjoy a <span class="bold">{{currentPeriodEndDate |date }}</span> free trial! After the trial period
                ends, you'll be charged
                <span class="bold">{{amount}} per {{planIntervalCount > 1 ? planIntervalCount + ' months' :
                    'month'}}</span>.
            </p>
            <div class="row">
                <div class="invalid-form-warning" *ngFor="let message of errorMessages">
                    <div class="invalid-icon"></div>
                    <div class="warning-message">
                        {{ message | translate }}
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4" style="gap: 1.5rem;">
                <button class="primary-button-large" (click)="goToPayment()">{{"PROCEED" |translate}}</button>
                <button class="secondary-button-large" *ngIf="employeeCompanies > 1"
                    (click)="selectCompanyDialog()">{{"CONTINUE_LATER" |translate}}</button>
            </div>
        </div>
        <div class="footer">
            <div>
                © Truckin Central Inc. {{year}}
            </div>
            <div>
                <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i> info&#64;truckincentral.com</a>
            </div>
        </div>
    </div>
    <div class="image-section">
        <img src="assets/images/payment-offer.png">
    </div>
</div>