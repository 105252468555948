<div class="container-fluid">
    <div class="row company-logo">
        <div class="col-6">
            <img class="logo-image" src="assets/images/site-logo.svg">
        </div>
    </div>
</div>
<div class="main-container">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3 class="heading-3">Mobile App Terms & Conditions</h3>
            </div>
        </div>
        <div class="tc-content-container mt-4">
            <div class="row mt-3">
                <div class="col-12">
                    <h4 class="heading-4">Acceptance of Terms</h4>
                    <p class="body-long-2">
                        By accessing and using the "Truckin Central" mobile app, you acknowledge that you have read,
                        understood, and agree to comply with these Terms and Conditions. If you do not agree with these
                        terms, please refrain from using the app. User Responsibilities 2.1 Registration: You are
                        responsible for providing accurate and up-to-date information during the registration process.
                        You must promptly update any changes to your personal or company details. 2.2 Account Security:
                        You are solely responsible for maintaining the confidentiality and security of your account
                        credentials. Any activities performed using your account will be considered your responsibility.
                        2.3 Compliance: You agree to comply with all applicable laws and regulations while using the
                        app. You must not engage in any unlawful activities or use the app for unauthorized purposes.
                        Privacy 3.1 Data Collection: We collect and store user information as outlined in our Privacy
                        Policy. By using the app, you consent to the collection, storage, and use of your data as
                        described. 3.2 Data Security: We take reasonable measures to protect your personal information,
                        but we cannot guarantee absolute security. You are responsible for safeguarding any personal or
                        sensitive information you provide. Intellectual Property 4.1 Ownership: All intellectual
                        property rights related to the app, including but not limited to trademarks, logos, and content,
                        are owned by "Truckin Central" or its licensors. 4.2 Use Restrictions: You may not copy, modify,
                        distribute, or reproduce any part of the app or its content without prior written permission
                        from "Truckin Central." Disclaimer of Liability 5.1 App Usage: The app is provided on an "as is"
                        and "as available" basis. We do not warrant that the app will be error-free, uninterrupted, or
                        meet your specific requirements. 5.2 Third-Party Content: The app may include links to
                        third-party websites or content. We are not responsible for the accuracy, availability, or
                        legality of such external resources. 5.3 Limitation of Liability: In no event shall "Truckin
                        Central" or its affiliates be liable for any direct, indirect, incidental, consequential, or
                        punitive damages arising from your use of the app. Modifications and Termination 6.1
                        Modifications: "Truckin Central" reserves the right to modify, suspend, or discontinue the app
                        or any part thereof, at any time without prior notice. 6.2 Termination: We may terminate or
                        suspend your access to the app for any reason, including violation of these Terms and
                        Conditions. 6.3 Effect of Termination: Upon termination, your right to use the app ceases
                        immediately, and you must delete all copies of the app from your device. Governing Law These
                        Terms and Conditions shall be governed by and construed in accordance with the laws of Illinois
                        State. Any disputes arising from the use of the app shall be subject to the exclusive
                        jurisdiction of the courts in Illinois State.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>