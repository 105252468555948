<ng-container *ngIf="showRootComponents; else doNotShowRootComponents">
    <div class="w-100">
        <app-navbar></app-navbar>
    </div>
    <div class="w-100 d-flex main" [class.other-safari]="!isSafari()" [class.safari]="isSafari()" [class.main-after-login]="contextService.loggedInUserId$ | async">
        <div class="sidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="w-100 main-container" [class.container-open]="opened" [class.container-close]="!opened"
            id="main-container">
            <ng-container *ngIf="loading;else notLoading">
                <div class="m-4">
                    <p-skeleton styleClass="mb-4"></p-skeleton>
                    <p-skeleton width="40rem" styleClass="mb-4"></p-skeleton>
                    <p-skeleton width="80rem" styleClass="mb-4"></p-skeleton>
                    <p-skeleton width="60rem" styleClass="mb-4"></p-skeleton>
                    <p-skeleton width="100rem" height="4rem" styleClass="mb-4"></p-skeleton>
                    <p-skeleton width="80rem" styleClass="mb-4"></p-skeleton>
                    <p-skeleton width="60rem" styleClass="mb-4"></p-skeleton>
                </div>
            </ng-container>
            <ng-template #notLoading>
                <router-outlet></router-outlet>
            </ng-template>
        </div>
    </div>
</ng-container>


<ng-template #doNotShowRootComponents>
    <ng-container *ngIf="!hideNavbar">
        <app-navbar [requiresLogin]="requiresLogin" [showNotification]="showNotification"></app-navbar>
    </ng-container>
    <div class="w-100 main" [class.main-without-navbar]="hideNavbar">
        <router-outlet></router-outlet>
    </div>
</ng-template>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white; font-size: 2rem">{{ "LOADING" | translate }} ...</p>
</ngx-spinner>
<app-shared-templates></app-shared-templates>

<!-- ////////////////////// -->

<p-toast [life]="5000" [style]="{width: '34rem'}" position="bottom-right"></p-toast>
<p-toast key="notification" [life]="10000" [style]="{width: '34rem'}">
    <ng-template let-message pTemplate="message">
        <div style="flex: 1;cursor: pointer;" (click)="notificationHandler(message.data.notificationObj)">
            <div class="d-flex align-items-center gap-3" style="margin-bottom: 0.7rem;">
                <i class="icon-bell-01" style="font-size: 18px;"></i>
                <span class="p-toast-summary "> {{message.summary}}</span>
            </div>
            <div class="p-toast-detail" style="margin-left: 3rem;">{{ message.detail }}</div>
        </div>
    </ng-template>
</p-toast>