import { DateService } from 'src/app/shared/services/date.service';
import { NotificationsApiService } from './../../../services/notifications-api.service';
import {
    TcNotification,
    NotificationReadTypeEnum,
    DropdownList,
} from './../../../model/shared.model';
import { FeatureService } from './../../../services/feature.service';
import { Languages } from './../../../../core/models/shared.model';
import { TranslateConfigService } from './../../../services/translate-config.service';
import { EmployeeCompany } from './../../../../core/models/company.model';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized, Scroll } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ContextService } from 'src/app/shared/services/context.service';
import { environment } from 'src/environments/environment';
import { NavigationStart, Event as NavigationEvent } from '@angular/router';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { HostListener } from '@angular/core';
import { Subscription, lastValueFrom } from 'rxjs';
import { JobOpeningsApiService } from 'src/app/modules/job-openings/services/job-openings-api.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnoozeReminderComponent } from '../../dialogs/snooze-reminder/snooze-reminder.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    backgroundColor: string = environment.navBarBackgroundColor;
    production: boolean = environment.production;
    @Input() requiresLogin: boolean = true;
    @Input() showNotification: boolean = true;
    showAdminBoard = false;
    showModeratorBoard = false;
    showCompanyMenu: boolean = false;
    employeeCompanies: EmployeeCompany[] = [];
    userName: string = '';
    companyLocationName: string = 'Loading companies ...';
    isLogin: boolean = false;
    isThereUnpaidInvoices: boolean = false;
    showLoginButton: boolean = true;
    currentLang: string = 'en';
    languages: Languages[] = [
        {
            label: 'EN',
            value: 'en',
        },
        {
            label: 'ES',
            value: 'es',
        },
    ];
    hideNavbar: boolean = false;
    companyComaniesSub: Subscription | null = null;
    contextSub: Subscription | null = null;
    notificationCount: number = 0;
    notificationsList: TcNotification[] = [];
    showAll: boolean = true;
    companyNameSub: Subscription | null = null;
    unpaidInvoicesSub: Subscription | null = null;
    notificationOptions: DropdownList[] = [
        {
            text: '',
            value: 0,
        },
    ];
    isBusy: number = 0;

    @ViewChild('notification_list') $notification_list: any;
    @ViewChild('notification_button') $notification_button: any;

    constructor(
        public authService: AuthService,
        public contextService: ContextService,
        public router: Router,
        private translate: TranslateConfigService,
        public featureService: FeatureService,
        public notificationService: NotificationsService,
        private notificationsApiService: NotificationsApiService,
        public dateService: DateService,
        private jobOpeningsApiService: JobOpeningsApiService,
        private sharedService: SharedService,
        private dialog: MatDialog
    ) {
        this.currentLang = localStorage.getItem('language') || 'en';
    }

    async ngOnInit() {
        this.hideNavbar = (this.router.url == '/app' || this.router.url == '/driver-app');

        this.contextSub = this.contextService.loggedInUserId$.subscribe(
            async (newUserId) => {
                this.isLogin = newUserId > 0 ? true : false;
                this.userName = this.contextService.getUserName();
            }
        );

        this.companyNameSub = this.contextService.companyName$.subscribe(
            (compLocName) => {
                this.companyLocationName = compLocName;
            }
        ); 
        
        this.unpaidInvoicesSub = this.contextService.isThereUnpaidInvoice$.subscribe(
            (isThereUnpaidInvoices) => {
                this.isThereUnpaidInvoices = isThereUnpaidInvoices;
            }
        );

        this.companyComaniesSub =
            this.contextService.employeeCompanies$.subscribe((companies) => {
                this.employeeCompanies = companies;
                // this.showCompanyMenu = this.contextService.isMoreThanOneLocation();
            });
        if (this.isLogin) {
            this.notificationService.listen();
        }
    }

    @HostListener('window:focus', ['$event'])
    async onFocus(event: FocusEvent) {
        if (this.isLogin) {
            this.notificationService.updateNotifications();
        }
    }

    async logout() {
        await this.authService.signout(this.notificationService.getDeviceId());
        await this.authService.signOut();
    }

    goToLogin() {
        this.router.navigate(['/auth/login']);
    }
    goToBilling() {
        this.router.navigate(['/settings/billing/invoices']);
    }

    goToUserSettings() {
        this.router.navigate(['/user-settings']);
    }

    async goToHome() {
        if (!this.requiresLogin) {
            window.location.href = 'http://www.truckincentral.com';
        } else {
            if (await this.authService.isLoggedIn())
                this.router.navigate(['/home']);
            else this.router.navigate(['/auth/login']);
        }
    }

    setLanguageWithRefresh(lang: any) {
        this.translate.setLanguage(lang);
        localStorage.setItem('language', lang);
        window.location.reload();
    }

    setLangValue(value: string) {
        this.currentLang = value;
    }

    async getNotificationsList(
        notificationReadType = NotificationReadTypeEnum.All
    ) {
        this.isBusy++;
        let userId = this.contextService.getUserId();
        try {
            let result = await this.notificationsApiService.notificationsTop(
                userId,
                notificationReadType,
                25
            );
            if (result.success && result.data) {
                this.notificationsList = result.data.pageData;
            } else {
            }
        } catch (error: any) {
        } finally {
            this.isBusy--;
        }
    }

    getNotifications() {
        this.getNotificationsList(
            this.showAll
                ? NotificationReadTypeEnum.All
                : NotificationReadTypeEnum.Unread
        );
    }

    async markAsRead(
        notification: TcNotification,
        isRead = !notification.isRead
    ) {
        notification.isRead = isRead;
        let userId = this.contextService.getUserId();
        let response = await this.notificationService.markAsRead(
            userId,
            notification.id,
            isRead
        );
        if (response.success) {
            let result = await this.notificationService.updateNotifications();
        }
    }

    async notificationLink(notification: TcNotification) {
        this.$notification_list.nativeElement.classList.remove('show');
        this.$notification_button.nativeElement.classList.remove('show');
        notification.isRead = true;
        await this.markAsRead(notification, true);
        this.notificationService.goToLink(notification);
    }

    seeAllNotification() {
        this.$notification_list.nativeElement.classList.remove('show');
        this.$notification_button.nativeElement.classList.remove('show');
        this.router.navigate(['/notifications']);
    }

    async closeJob(notification: TcNotification) {
        try {
            let data = JSON.parse(notification.data);
            let response = await this.jobOpeningsApiService.closeJob(
                data.JobOpeningId
            );
            if (response.success) {
                this.sharedService.alertSuccessMessage();
                this.$notification_list.nativeElement.classList.remove('show');
                this.$notification_button.nativeElement.classList.remove(
                    'show'
                );
                notification.isRead = true;
                this.notificationService.goToLink(notification);
                await this.markAsRead(notification, true);
            }
        } catch (error: any) {
            this.sharedService.alertDangerMessage();
        }
    }

    async startMaintenanceDue(notification: TcNotification) {
        this.$notification_list.nativeElement.classList.remove('show');
        this.$notification_button.nativeElement.classList.remove(
            'show'
        );
        notification.isRead = true;
        this.notificationService.goToLink(notification);
        await this.markAsRead(notification, true);
    }

    getNotificationTitle(title: string) {
        if (title.length >= 30) return title.substring(0, 30).concat('...');
        else return title;
    }

    async snoozeReminder(notificationId: number) {
        let dialog = this.dialog.open(SnoozeReminderComponent, {
            disableClose: true,
            width: '29.9rem',
            data: {
                notificationId: notificationId
            },
        });
        let resultContract = await lastValueFrom(dialog.afterClosed());
        if (resultContract == 'success') {
            this.sharedService.alertSuccessMessage();
            this.getNotificationsList();
        }
    }

    ngOnDestroy(): void {
        if (this.contextSub) this.contextSub.unsubscribe();
        if (this.companyNameSub) this.companyNameSub.unsubscribe();
        if (this.companyComaniesSub) this.companyComaniesSub.unsubscribe();
    }
}
