import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppVersionService } from 'src/app/shared/services/app-version.service';

@Component({
  selector: 'app-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  styleUrl: './maintenance-mode.component.scss'
})
export class MaintenanceModeComponent implements OnInit {

    constructor(
        private appVersionService: AppVersionService,
        private router: Router
    ){}

    isBusy: boolean = false;

    ngOnInit(): void {
        window.localStorage.setItem('check-version-count', '0');
        this.checkVersion();
    }

    reload(){
        window.location.reload();
    }

    async checkVersion(){
        try {
            this.isBusy = true;
            let response = await this.appVersionService.getAppVersion();
            if(response.success && response.data) {
                if(!response.data.isUpToDate) {
                    if(!response.data.isMandatoryUpdate) {
                        this.router.navigate(['/home']);
                    }
                } else {
                    this.router.navigate(['/home']);
                }
            }
        } catch (error) {

        } finally {
            this.isBusy = false;
        }
    }

}
