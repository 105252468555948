import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { BillingSettingsApiService } from 'src/app/shared/services/billing-settings-api.service';
import { environment } from 'src/environments/environment';



@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
    stripe: Stripe | null = null;
    elements: StripeElements | undefined;
    customerId: string | null = null;

    year: number = new Date().getFullYear();
    errorMessages: Set<string> = new Set();
    addNew: boolean = false;

    constructor(
        private spinner: NgxSpinnerService,
        private billingSettingsApiService: BillingSettingsApiService,
        private route: ActivatedRoute
    ) { }

    async ngOnInit() {
        this.addNew = this.route.snapshot.queryParamMap.get('addNew') === 'true';
        this.billingInitSetup();
    }

    async billingInitSetup() {
        try {
            this.spinner.show();
            let response = await this.billingSettingsApiService.initSetupApi();
            if (response.success) {
                if (response.data)
                    await this.loadStripe(response.data.publishableKey, response.data.setupIntentClientSecret)
            }
        } catch (error) {

        } finally {
            setTimeout(() => {
                this.spinner.hide();
            }, 1000);
        }
    }

    async loadStripe(publishableKey: string, setupIntentClientSecret: string) {
        try {
            // this.spinner.show();
            this.stripe = await loadStripe(publishableKey);

            // Initialize Stripe Elements with appearance and clientSecret
            if (this.stripe && setupIntentClientSecret) {
                const appearance = {
                    theme: 'stripe',
                };
                this.elements = this.stripe.elements({
                    // appearance,
                    clientSecret: setupIntentClientSecret,
                });

                if (this.elements) {
                    // Create a Payment Element
                    const paymentElement = this.elements.create('payment');

                    // Mount the Payment Element to the DOM
                    paymentElement.mount('#setup-element');
                }
            }
        } catch (error) {

        } finally {
        }
    }

    async handleSave() {
        try {
            this.errorMessages.clear();
            this.spinner.show();
            if (!this.stripe || !this.elements) {
                return;
            }
            const result = await this.stripe.confirmSetup({
                elements: this.elements,
                confirmParams: {
                    return_url: this.addNew ? `${environment.webUrl}/settings/billing/payment-methods`: `${environment.webUrl}/free-trial-activated`,
                },
            });
            if (result.error) {
                // Handle error here
                if (result.error.message)
                    this.errorMessages.add(result.error.message);
            } else {
                // Handle successful setup here
                console.log('Setup complete!');
            }
        } catch (error) {
        } finally {
            this.spinner.hide();
        }
    }

}
