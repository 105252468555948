import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-terms-and-conditions',
  templateUrl: './mobile-terms-and-conditions.component.html',
  styleUrls: ['./mobile-terms-and-conditions.component.scss']
})
export class MobileTermsAndConditionsComponent {

}
