import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
declare var bootstrap: any

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => {
        // handle error
    });

interface Window {
    initMap(): void;
}

declare var window: Window;

document.addEventListener('DOMContentLoaded', (event) => {
    loadAPI();
});

// In your component or service
function loadAPI() {
    window.initMap = function initMap() {
        // Your Google Maps code here
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBoCKS6yFyydOUUNz3o3lWsf3KOSQWWEHM&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
}

setTimeout(() => {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    const popoverList = popoverTriggerList.map(function (popoverTriggerEl: any) {
        return new bootstrap.Popover(popoverTriggerEl);
    });
}, 3000);