<!-- 
<div class="container">
    <div class="row">
        <div class="col-md-6 col-12">

        </div>
        <div class="col-md-6 col-12">
            <div id="setup-element"></div>
            <button class="primary-button-large mt-3" (click)="handleSave()">Save Card</button>
        </div>
    </div>

</div> -->

<div class="page-container">
    <div class="page-section">
        <div class="logo">
            <img src="assets/images/site-logo.svg">
        </div>
        <div class="body">
            <div id="setup-element"></div>
            <div class="row">
                <div class="invalid-form-warning" *ngFor="let message of errorMessages">
                    <div class="invalid-icon"></div>
                    <div class="warning-message">
                        {{ message | translate }}
                    </div>
                </div>
            </div>
            <div style="padding-right: 1rem;">
                <button class="primary-button-large mt-3 w-100" (click)="handleSave()">{{"SAVE_CARD"
                    |translate}}</button>
            </div>
        </div>
        <div class="footer">
            <div>
                © Truckin Central Inc. {{year}}
            </div>
            <div>
                <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i> info&#64;truckincentral.com</a>
            </div>
        </div>
    </div>
    <div class="image-section">
        <img src="assets/images/payment.png">
    </div>
</div>