import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from 'src/app/shared/services/context.service';

@Component({
  selector: 'app-no-permissions',
  templateUrl: './no-permissions.component.html',
  styleUrls: ['./no-permissions.component.scss']
})
export class NoPermissionsComponent {

    constructor(
        private contextService: ContextService,
        private router: Router
    ){
        const PERMISSIONS_KEY = 'user-permissions';
        const permissionList = localStorage.getItem(PERMISSIONS_KEY)
            ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY) ?? '')
            : null;
        if (permissionList && permissionList.userResourcesPermissions.length > 1) {
            this.router.navigate(['/home'])
        }
    }

    contact(){
        let email = this.contextService.getCompanyEmail();
        window.location.href = `mailto:${email}`;
    }

}
