<ng-container *ngIf="isBusy <= 0">
    <ng-container *ngIf="tokenValid">
        <ng-container *ngIf="isDesktop;else mobile">
            <div class="container">
                <div class="row align-items-center main-content">
                    <div class="col-lg-4 col-12">
                        <div class="title">
                            <h2 class="heading-2">{{"THANK_YOU_FOR_VERIFYING_YOUR_EMAIL_ADDRESS" |translate}}</h2>
                        </div>
                        <div class="desc">
                            <p class="body-short-2">
                                You're just one step away from unlocking all the amazing features of our app.
                            </p>
                            <p class="body-short-2">
                                <span style="font-weight: 600;">Please note that our service is exclusively available
                                    through our mobile app.</span> Make sure to download it to enjoy all that we have to
                                offer.
                            </p>
                        </div>
                        <div class="scan-qr">
                            <div class="qr">
                                <div class="qrcodeImage">
                                    <qrcode [qrdata]="link" [allowEmptyString]="true" [cssClass]="'center'"
                                        [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [elementType]="'canvas'"
                                        [errorCorrectionLevel]="'M'" [margin]="4" [scale]="1" [width]="200"></qrcode>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 d-flex justify-content-end">
                                <a href="https://apps.apple.com/app/truckin-central/id6443468920" target="_blank"><img
                                        src="/assets/images/App Store.svg"></a>
                            </div>
                            <div class="col-6">
                                <a href="https://play.google.com/store/apps/details?id=com.truckincentral.driver"
                                    target="_blank"><img src="/assets/images/Google Play.svg"></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-12" style="text-align: center;">
                        <img class="main-image" src="/assets/images/driver-app.png">
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #mobile>
            <div class="mobile-container">
                <div class="logo">
                    <img src="/assets/images/site-logo.svg">
                </div>
                <div class="mobile-desc-body">
                    <div class="image">
                        <img src="/assets/images/check-icon-app-process.svg">
                    </div>
                    <h4 class="heading-4">
                        Thank You for Verifying Your Email Address!
                    </h4>
                    <p class="body-short-2">
                        You're just one step away from unlocking all the amazing features of our app.
                    </p>
                </div>
                <div class="links">
                    <p class="body-short-2">Ready to get started?</p>
                    <div class="row">
                        <div class="col-6 d-flex justify-content-end">
                            <a href="https://apps.apple.com/app/truckin-central/id6443468920" target="_blank"><img
                                    src="/assets/images/App Store.svg"></a>
                        </div>
                        <div class="col-6">
                            <a href="https://play.google.com/store/apps/details?id=com.truckincentral.driver"
                                target="_blank"><img src="/assets/images/Google Play.svg"></a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="!tokenValid">
        <div class="container no-longer-valid">
            <div class="row align-items-center main-content w-100">
                <div class="col-lg-6 col-12">
                    <div class="title">
                        <h2 class="heading-2">
                            Email Verification Link <br> no longer valid!
                        </h2>
                    </div>
                    <button class="secondary-button-large contact-support-button" (click)="contactSupport()">
                        Contact Support
                    </button>
                </div>
                <div class="col-lg-6 col-12" style="text-align: center;">
                    <img class="main-image amico-image" src="/assets/images/amico-expired.png">
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
