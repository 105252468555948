<header class="page-header" [class.d-none]="hideNavbar">
    <div [class.container]="!isLogin" [class.container-fluid]="isLogin">
        <div class="row">
            <div class="col-6" [class.pageContentPadding]="isLogin">
                <section class="clearfix">
                    <!-- <div class="logo-icon" (click)="goToHome()"></div> -->
                    <a routerLink="home"><img class="logo-image" (click)="goToHome()"
                            src="assets/images/site-logo.svg"></a>
                </section>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-center navbar-right-section"
                [class.right-navbar]="isLogin">
                <div *ngIf="isLogin && isThereUnpaidInvoices">
                    <button (click)="goToBilling()" class="primary-button-large red alert-icon">
                        <span>{{"UNPAID_INVOICES" |translate}}</span>
                    </button>
                </div>
                <div class="lan change-lang" *ngIf="false">
                    <div class="dropdown dropdown-custom">
                        <button class="btn btn-secondary" type="button" id="change-language" data-bs-toggle="dropdown"
                            aria-expanded="false" aria-haspopup="true">
                            <span class="lang-label body-short-1">{{
                                currentLang
                                }}</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="change-language">
                            <li *ngFor="let lang of languages" [class.active]="currentLang == lang.value">
                                <a class="dropdown-item" href="javascript:void(0)" (click)="
                                        setLangValue(lang.value);
                                        setLanguageWithRefresh(lang.value)
                                    " (keyup.enter)="
                                        setLangValue(lang.value);
                                        setLanguageWithRefresh(lang.value)
                                    ">{{ lang.label | translate }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="dropdown notifications-dropdown" *ngIf="isLogin && requiresLogin && showNotification">
                    <button #notification_button class="btn btn-secondary notifications-dropdown-button" type="button"
                        id="notification-list" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                        aria-expanded="false" (click)="getNotifications()">
                        <img src="../../../../../assets/images/notifications-icon.svg" />
                        <span class="notifications-count" *ngIf="
                                ((notificationService.notificationsCount$
                                    | async) ??
                                    0) >
                                0
                            ">{{
                            ((notificationService.notificationsCount$
                            | async) ??
                            0) >
                            99
                            ? "99+"
                            : (notificationService.notificationsCount$
                            | async)
                            }}</span>
                    </button>
                    <ul #notification_list class="dropdown-menu notification-list-menu"
                        aria-labelledby="notification-list" [ngClass]="isBusy > 0 ? 'wrapper-disabled' : ''">
                        <div class="row m-1 pt-2 d-flex align-items-baseline">
                            <div class="col-4">
                                <h5 class="heading-5">
                                    {{ "NOTIFICATIONS" | translate }}
                                </h5>
                            </div>
                            <div class="col-8">
                                <div class="form-check form-switch">
                                    <label class="form-check-label body-short-2" for="show-all-alerts">{{
                                        "SHOW_ALL_ALERTS" | translate
                                        }}</label>
                                    <input class="form-check-input" type="checkbox" id="show-all-alerts"
                                        [(ngModel)]="showAll" (ngModelChange)="getNotifications()" />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div *ngIf="
                                notificationsList.length == 0;
                                else hasNotification
                            " class="notification-empty">
                            <div class="empty-message mt-4">
                                <ng-container *ngIf="isBusy; else notBusy">
                                    <p class="body-short-2">
                                        {{ "LOADING" | translate }} ...
                                    </p>
                                </ng-container>
                                <ng-template #notBusy>
                                    <h6 class="heading-6">
                                        {{
                                        "NOTIFICATION_ARE_NOT_AVAILABLE_RIGHT_NOW"
                                        | translate
                                        }}
                                    </h6>
                                    <p class="body-short-2">
                                        {{
                                        "PLEASE_TRY_AGAIN_LATER" | translate
                                        }}
                                    </p>
                                </ng-template>
                            </div>
                        </div>
                        <ng-template #hasNotification>
                            <div class="notification-list">
                                <li *ngFor="
                                        let notification of notificationsList
                                    " class="dropdown-item" [class.unread]="
                                        notification.isRead == false
                                    ">
                                    <div class="notifications-container">
                                        <div class="row">
                                            <div class="col-11" (click)="
                                                    notificationLink(
                                                        notification
                                                    );
                                                    $event.stopPropagation()
                                                ">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <div class="notification-icon">
                                                            <i
                                                                class="{{notificationService.getNotificationIcon(notification.alertType)}}"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-10">
                                                        <div class="row">
                                                            <div class="col-sm-9 col-8">
                                                                <h6 class="heading-6 notification-title"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    title="{{
                                                                        notification.alertTitle
                                                                    }}">
                                                                    {{
                                                                    getNotificationTitle(notification.alertTitle)
                                                                    }}
                                                                    <span *ngIf="
                                                                            !notification.isRead
                                                                        " class="dot"></span>
                                                                </h6>
                                                            </div>
                                                            <div class="col-sm-3 col-4">
                                                                <span class="label-1 number-of-day">{{
                                                                    dateService.getTimeAgo(
                                                                    notification.createDate
                                                                    )
                                                                    }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <span class="body-short-2 notification-desc">{{
                                                                notification.alertDescription
                                                                }}</span>
                                                        </div>
                                                        <div class="notification-buttons">
                                                            <div class="row justify-content-end">
                                                                <div class="col-md-6 col-12">
                                                                    <span id="snooze"
                                                                        class="secondary-button-large notification-action-button snooze-button"
                                                                        (click)="snoozeReminder(notification.id);$event.stopPropagation()">
                                                                        <span>{{"REMINDE_ME_LATER" |translate}}</span>
                                                                    </span>
                                                                </div>
                                                                <div class="col-md-6 col-12"
                                                                    *ngIf="notification.alertType == 'RequiredDriversFulfilled'">
                                                                    <span id="close-job"
                                                                        class="secondary-button-large notification-action-button"
                                                                        (click)="closeJob(notification);$event.stopPropagation()">
                                                                        {{"CLOSE_JOB" |translate}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-md-6 col-12"
                                                                    *ngIf="notification.alertType == 'MaintenanceIsDue'">
                                                                    <button id="start-mantenance"
                                                                        class="primary-button-large"
                                                                        (click)="startMaintenanceDue(notification);$event.stopPropagation()">
                                                                        {{"START" |translate}}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="row justify-content-end mt-4">
                                                            <div class="col-md-6 col-12">
                                                                <button id="snooze"
                                                                    (click)="snoozeReminder(notification.id);$event.stopPropagation()"
                                                                    class="secondary-button-large notification-action-button snooze-button">
                                                                    <span>{{"SNOOZE" |translate}}</span>
                                                                </button>
                                                            </div>
                                                            <div class="col-md-6 col-12"
                                                                *ngIf="notification.alertType == 'RequiredDriversFulfilled'">
                                                                <button id="snooze"
                                                                    class="secondary-button-large notification-action-button"
                                                                    (click)="closeJob(notification);$event.stopPropagation()">
                                                                    {{"CLOSE_JOB" |translate}}
                                                                </button>
                                                            </div>
                                                            <div class="col-md-6 col-12"
                                                                *ngIf="notification.alertType == 'MaintenanceIsDue'">
                                                                <button id="start" class="primary-button-large"
                                                                    (click)="startMaintenanceDue(notification);$event.stopPropagation()">
                                                                    {{"START" |translate}}
                                                                </button>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1 notification-options">
                                                <img src="../../../../../assets/images/three-dots.svg"
                                                    id="notification-option" data-bs-toggle="dropdown"
                                                    aria-expanded="false" aria-haspopup="true" />
                                                <ul class="dropdown-menu" aria-labelledby="notification-option">
                                                    <li *ngFor="
                                                            let option of notificationOptions
                                                        ">
                                                        <a class="dropdown-item body-short-1" href="javascript:void(0)"
                                                            (click)="
                                                                markAsRead(
                                                                    notification
                                                                )
                                                            " (keyup.enter)="
                                                                markAsRead(
                                                                    notification
                                                                )
                                                            ">
                                                            {{
                                                            option.text
                                                            | translate
                                                            }}
                                                            <span *ngIf="
                                                                    !notification.isRead
                                                                ">{{
                                                                "MARK_AS_READ"
                                                                | translate
                                                                }}</span>
                                                            <span *ngIf="
                                                                    notification.isRead
                                                                ">{{
                                                                "MARK_AS_UNREAD"
                                                                | translate
                                                                }}</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                            <li class="see-all-notification">
                                <div class="row" (click)="seeAllNotification()">
                                    <a class="body-short-2">{{
                                        "SEE_ALL_NOTIFICATIONS" | translate
                                        }}</a>
                                </div>
                            </li>

                        </ng-template>
                    </ul>
                </div>
                @if((contextService.loggedInUserId$ |async) == 0){
                <div class="btn-link-wrapper float-right">
                    <button href="javascript:void(0)" (click)="goToLogin()" class="btn-link btn-colored login-nav-btn"
                        id="navbar-login-button">
                        {{ "LOGIN" | translate }}
                    </button>
                </div>
                }
                <div class="user-profile-wrap float-right pr-0" *ngIf="
                    requiresLogin && isLogin
                    ">
                    <button mat-flat-button [matMenuTriggerFor]="profileMenu" xPoisition="before" yPosition="below"
                        class="btn-transparent px-0 clearfix">
                        <a href="javascript:void(0)" class="user-profile float-right" [ngClass]="
                                !(contextService.isSetupCompleted$ | async)
                                    ? 'profileActive'
                                    : ''
                            ">
                            <img src="../../../../assets/images/userProfile.svg" alt="User Profile" />
                        </a>
                    </button>

                    <mat-menu #profileMenu="matMenu">
                        <ng-template matMenuContent>
                            <div class="profilePicWrap">
                                <img src="../../../../assets/images/userProfile.svg" alt=""
                                    class="profilePicture active" />
                            </div>
                            <h5 class="heading-5 text-center">{{ userName }}</h5>
                            @if(contextService.isAccountActive$.getValue()){
                            <button mat-menu-item (click)="goToUserSettings()" class="mat-menu-item mt-3"
                                *ngIf="contextService.getCompanyId() || contextService.isSysAdmin()">
                                <div class="user-actions">
                                    <i class="icon-user-01 icon-s-m"></i>
                                    <span class="body-short-1">{{"USER_SETTINGS" |translate}}</span>
                                </div>
                            </button>
                            }
                            <button mat-menu-item (click)="logout()" class="mat-menu-item">
                                <div class="user-actions">
                                    <i class="icon-log-in-02 icon-s-m"></i>
                                    <span class="body-short-1">{{"SIGN_OUT" |translate}}</span>
                                </div>
                            </button>
                        </ng-template>
                    </mat-menu>
                </div>
            </div>
        </div>
        <!-- Row -->
    </div>
    <!-- Container Fluid -->
</header>
<!-- Header -->