

<ng-container *ngIf="isDesktop;else mobile">
    <div class="container">
        <div class="row align-items-center main-content">
            <div class="col-lg-4 col-12">
                <div class="title">
                    <h2 class="heading-2">{{"ALMOST_THERE" |translate}}</h2>
                </div>
                <div class="desc">
                    <p class="body-short-2">
                        {{"DRIVER_APP_PAGE_DESC" |translate}}
                    </p>
                    <p class="body-short-2">
                        {{"WHY_USE_THE_TRUCKIN_CENTRAL_MOBILE_APP" |translate}}
                    </p>
                    <ul>
                        <li class="body-short-2">{{"PLAN_ROUTES" |translate}}</li>
                        <li class="body-short-2">{{"KEEP_TRACK_OF_YOUR_DOCUMENTS" |translate}}</li>
                        <li class="body-short-2">{{"FIND_NEARBY_SERVICES" |translate}}</li>
                    </ul>
                    <p class="body-short-2">
                        {{"DRIVER_APP_PAGE_DESC_2" |translate}}
                    </p>
                </div>
                <div class="scan-qr">
                    <div class="qr">
                        <div class="qrcodeImage">
                            <qrcode [qrdata]="link" [allowEmptyString]="true"
                                [cssClass]="'center'"
                                [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [elementType]="'canvas'"
                                [errorCorrectionLevel]="'M'" [margin]="4" [scale]="1" [width]="200"></qrcode>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 d-flex justify-content-end">
                        <a href="https://apps.apple.com/app/truckin-central/id6443468920" target="_blank"><img src="/assets/images/App Store.svg"></a>
                    </div> 
                    <div class="col-6">
                        <a href="https://play.google.com/store/apps/details?id=com.truckincentral.driver" target="_blank"><img src="/assets/images/Google Play.svg"></a>
                    </div> 
                </div>
            </div>
            <div class="col-lg-8 col-12" style="text-align: center;">
                <img class="main-image" src="/assets/images/driver-app.png">
            </div>
        </div>
    </div>
</ng-container>
<ng-template #mobile>
    <div class="item">
        <div class="image">
          <img src="../../../assets/images/pana.png">
        </div>
        <div class="title">
          <h1>We're glad you're here!</h1>
          <p>You'll be redirected shortly, please wait...</p>
        </div>
        <div class="loading-container">
          <div class="ball-1"></div>
          <div class="ball-2"></div>
          <div class="ball-3"></div>
        </div>
      </div>
</ng-template>

