import { ContextService } from 'src/app/shared/services/context.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BillingSettingsApiService } from 'src/app/shared/services/billing-settings-api.service';
import { SharedModule } from "../../shared/shared.module";

@Component({
  selector: 'app-free-trial-activated',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    SharedModule
],
  templateUrl: './free-trial-activated.component.html',
  styleUrl: './free-trial-activated.component.scss'
})
export class FreeTrialActivatedComponent implements OnInit {

    constructor(
        private router: Router,
        private billingSettingsApiService: BillingSettingsApiService,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
        private contextService: ContextService
    ){}

    year: number = new Date().getFullYear();
    errorMessages: Set<string> = new Set();
    trialEndDate: Date | null = null;
    amount: number = 0;

    ngOnInit(): void {
        this.getData();
        this.route.queryParams.subscribe(params => {
            const setupIntent: string = params['setup_intent'];
            this.confirmSetup(setupIntent);
        });
    }

    async getData(){
        try {
            this.errorMessages.clear();
            this.spinner.show();
            let response = await this.billingSettingsApiService.subscription();
            if(response.success && response.data) {
                this.trialEndDate = response.data.trialEndDate;
                this.amount = response.data.amount;
            }
        } catch (error: any) {
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        } finally {
            this.spinner.hide();
        }
    }

    async confirmSetup(setupIntentId: string) {
        try {
            let response = await this.billingSettingsApiService.confirmSetup(setupIntentId,true);
            if (response.success) {
            }
        } catch (error: any) {
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        } finally {
        }
    }

    async goToHome(){
        await this.contextService.isPaymentMethodSet();
        this.router.navigateByUrl('/home');
    }

}
