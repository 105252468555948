import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppleLoginProvider } from './core/providers/apple-login.provider';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor, authInterceptorProviders } from './core/interceptors/auth.interceptor';
import { SidebarComponent } from './shared/components/header/sidebar/sidebar.component';
import { DatePipe } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { NavbarComponent } from './shared/components/header/navbar/navbar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlineStatusModule } from 'ngx-online-status';
import {
    GoogleLoginProvider,
    MicrosoftLoginProvider,
    SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { AuthRedirectComponent } from './pages/auth-redirect/auth-redirect.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LogoutRedirectComponent } from './pages/logout-redirect/logout-redirect.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MobileTermsAndConditionsComponent } from './pages/mobile-terms-and-conditions/mobile-terms-and-conditions.component';
import { CarrierAccountActivationComponent } from './pages/carrier-account-activation/carrier-account-activation.component';
import { ContextService } from './shared/services/context.service';
import { TokenValidationsComponent } from './pages/token-validations/token-validations.component';
import { DriverAppComponent } from './pages/driver-app/driver-app.component';
import { EmployeeAccountActivationComponent } from './pages/employee-account-activation/employee-account-activation.component';
import { NoPermissionsComponent } from './pages/no-permissions/no-permissions.component';
import { DriverAppVerifyEmailComponent } from './pages/driver-app/driver-app-verify-email/driver-app-verify-email.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { PaymentComponent } from './pages/payment/payment.component';
import { DeactivatedAccountSubscriptionComponent } from './pages/deactivated-account-subscription/deactivated-account-subscription.component';
import { SharedTemplatesComponent } from './shared/components/shared-templates/shared-templates.component';
import { MaintenanceModeComponent } from './pages/maintenance-mode/maintenance-mode.component';
import { BackgroundScreeningCancellationRequestComponent } from './pages/background-screening-cancellation-request/background-screening-cancellation-request.component';

initializeApp(environment.firebase);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({ declarations: [
        PaymentComponent,
        AppComponent,
        SidebarComponent,
        NavbarComponent,
        SharedTemplatesComponent,
        TermsAndConditionsComponent,
        AuthRedirectComponent,
        LogoutRedirectComponent,
        MobileTermsAndConditionsComponent,
        // CarrierAccountActivationComponent,
        TokenValidationsComponent,
        DriverAppComponent,
        // EmployeeAccountActivationComponent,
        NoPermissionsComponent,
        DriverAppVerifyEmailComponent,
        // DriverAppInvitationComponent,
        DeactivatedAccountSubscriptionComponent,
        MaintenanceModeComponent,
        BackgroundScreeningCancellationRequestComponent
    ],
    exports: [
        ToastModule
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        // ChatAppModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        MatMenuModule,
        MatDialogModule,
        // SocialLoginModule,
        OnlineStatusModule,
        MatDialogModule,
        QRCodeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics),
        NgxGoogleAnalyticsRouterModule,
        NgbPopoverModule,
        ToastModule,
        SkeletonModule], providers: [
        MessageService,
        DatePipe,
        authInterceptorProviders,
        //AccessGuardService,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [ContextService],
            multi: true,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('1096505717022-o502vhq9i6aeq8agpu3d7hpflp1odfbd.apps.googleusercontent.com', {
                            // scopes: 'https://www.googleapis.com/auth/gmail.send',
                            oneTapEnabled: false,
                        }),
                    },
                    {
                        id: AppleLoginProvider.PROVIDER_ID,
                        provider: new AppleLoginProvider('com.truckincentral.webapp'),
                    },
                    {
                        id: MicrosoftLoginProvider.PROVIDER_ID,
                        provider: new MicrosoftLoginProvider('43d01497-c161-4c4d-900f-88bd9b996d9f', {
                            redirect_uri: `${environment.webUrl}/auth-redirect`,
                            // scopes: [
                            //     'openid',
                            //     'email',
                            //     'profile',
                            //     'offline_access',
                            // 'Mail.Send'
                            // ],
                        }),
                    },
                    // {
                    //   id: FacebookLoginProvider.PROVIDER_ID,
                    //   provider: new FacebookLoginProvider('clientId')
                    // }
                ],
            } as SocialAuthServiceConfig,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }


export function startupServiceFactory(startupService: ContextService) {
    return () => startupService.init();
}