import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-logout-redirect',
    templateUrl: './logout-redirect.component.html',
    styleUrls: ['./logout-redirect.component.scss'],
})
export class LogoutRedirectComponent {
    constructor(private route: ActivatedRoute) {}
    ngOnInit() {
        console.log(this.route);
    }
}
