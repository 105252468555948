import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-background-screening-cancellation-request',
  templateUrl: './background-screening-cancellation-request.component.html',
  styleUrl: './background-screening-cancellation-request.component.scss'
})
export class BackgroundScreeningCancellationRequestComponent {

    constructor(){}

    year: number = new Date().getFullYear();
    errorMessages: Set<string> = new Set();

    form = new FormGroup({
        cancelRequestStatus: new FormControl("")
    })
    async submit(){

    }
}
