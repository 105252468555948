<div class="page-container">
    <div class="page-section">
        <div class="logo">
            <!-- <img src="assets/images/site-logo.svg"> -->
        </div>
        <div class="body">
            <h3 class="heading-3 bold">{{"ACCOUNT_SUBSCRIPTION_DEACTIVATED" |translate}}</h3>

            <p class="heading-5">
                {{ "ACCOUNT_SUBSCRIPTION_DEACTIVATED_DESC1" | translate }} 
                <strong>{{ selectedCompanyName }}</strong> 
                {{ "ACCOUNT_SUBSCRIPTION_DEACTIVATED_DESC" | translate }}
            </p>   
            <!-- <ul class="heading-5">
                <li>
                    {{"OVERDUE_PAYMENT" |translate}}
                </li>
                <li>
                    {{"MISSING_PAYMENT_METHOD" | translate}}
                </li>
                <li>
                    {{"ACCEPTED_CANCELLATION_REQUEST" |translate}}
                </li>
            </ul> -->
            <div class="d-flex mt-4" style="gap: 1.5rem;">
                <button class="primary-button-large" (click)="contactSupport()">{{"CONTACT_SUPPORT" |translate}}</button>
                <button class="secondary-button-large" *ngIf="employeeCompanies > 1" (click)="selectCompanyDialog()">{{"CONTINUE_LATER" |translate}}</button>             
            </div>
        </div>
        <div class="footer">
            <div>
                © Truckin Central Inc. {{year}}
            </div>
            <div>
                <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i> info&#64;truckincentral.com</a>
            </div>
        </div>
    </div>
    <div class="image-section">
        <img src="assets/images/deactivated-account-subscription.png">
    </div>
</div>