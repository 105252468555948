import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-driver-app',
    templateUrl: './driver-app.component.html',
    styleUrls: ['./driver-app.component.scss']
})
export class DriverAppComponent implements OnInit {

    constructor(
        public platform: Platform
    ) { }
    link: string = "";
    isDesktop: boolean = false;

    ngOnInit(): void {
        this.link = window.location.href;
        if (this.platform.IOS) {
            setTimeout(() => {
                window.location.href = "https://apps.apple.com/app/truckin-central/id6443468920"
            }, 4000);
        } else if (this.platform.ANDROID) {
            setTimeout(() => {
                window.location.href = 'https://play.google.com/store/apps/details?id=com.truckincentral.driver';
            }, 4000);
        } else {
            this.isDesktop = true;
        }
    }

}
