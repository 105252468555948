import { LayoutConfigurationDataService } from './shared/services/layout-configuration-data.service';
import { AuthService } from './shared/services/auth.service';
import { NotificationsService } from './shared/services/notifications.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import {
    Router,
    NavigationEnd,
    NavigationStart,
    GuardsCheckEnd,
    NavigationCancel,
    ActivatedRoute,
    NavigationSkipped,
    RoutesRecognized,
} from '@angular/router';
import { ContextService } from './shared/services/context.service';
import { PermissionsService } from './shared/services/permissions.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NotificationPermissionComponent } from './shared/components/dialogs/notification-permission/notification-permission.component';
import { TranslateConfigService } from './shared/services/translate-config.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { TcNotification } from './shared/model/shared.model';
import { SidebarComponent } from './shared/components/header/sidebar/sidebar.component';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppVersionService } from './shared/services/app-version.service';

const APP_MENU_OPEND_KEY = 'menu-opend';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

    @HostListener('window:focus', ['$event'])
    async onFocus(event: FocusEvent) {
        this.appVersionService.checkAppVersion();
    }

    @ViewChild(SidebarComponent)
    sidebarComponent: SidebarComponent | null = null;

    showRootComponents: boolean = false;
    hideNavbar: boolean = true;
    interval: any;
    routerSub: Subscription | null = null;
    sideMenuSub: Subscription | null = null;
    requiresLogin: boolean = true;
    showNotification: boolean = false;
    opened =
        window.localStorage.getItem(APP_MENU_OPEND_KEY) == 'true'
            ? true
            : false;
    loading = false;
    constructor(
        public router: Router,
        private shared: SharedService,
        public contextService: ContextService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private permissionService: PermissionsService,
        public dialog: MatDialog,
        private translate: TranslateConfigService,
        config: NgbPopoverConfig,
        private title: Title,
        private route: ActivatedRoute,
        private layoutConfigurationDataService: LayoutConfigurationDataService,
        private appVersionService: AppVersionService
    ) {
        config.triggers = 'hover';
        config.container = 'body';
        this.translate.setLanguage(localStorage.getItem('language') ?? 'en');
        this.layoutConfigurationDataService.hideNavbar$.subscribe(val => {
            this.hideNavbar = val;
        })
        this.layoutConfigurationDataService.showRootComponents$.subscribe(val => {
            this.showRootComponents = val ?
                (this.contextService.isSysAdmin() || this.contextService.employeeCompanies$.getValue().length > 0) : false;
        });
        this.layoutConfigurationDataService.requiresLogin$.subscribe(val => {
            this.requiresLogin = val;
        })
        this.layoutConfigurationDataService.showNotification$.subscribe(val => {
            this.showNotification = val;
        })
        this.routerSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                let url = this.router.url;
                const match = url.match(/\/([^/]+)/);
                if (match && match.length > 1) {
                    const firstText = match[1];
                    if (!event.url.includes(firstText)) {
                        this.loading = true;
                    }
                }
            }
            else if (event instanceof NavigationEnd) {
                this.loading = false;
            }
            else {
                this.loading = false;
            }
        });
    }

    async notificationHandler(notification: TcNotification) {
        let url = `/notifications/notification-handler/${notification.Id}`;
        this.router.navigateByUrl(url);
    }

    async ngOnInit() {
        // await this.contextService.init();
        // this.notificationsService.registerNotifications();
        this.appVersionService.checkAppVersion();
        this.sideMenuSub = this.shared.menuOpend$.subscribe((opened) => {
            this.opened = opened;
        });

        this.interval = setInterval(async () => {
            if (this.contextService.getUserId() > 0) {
                await this.permissionService.getAllUserPermissions(false);
                if (this.sidebarComponent) {
                    this.sidebarComponent.assignSidebarItems();
                }
            }
        }, 300000); // = 5 * 60 * 1000

        this.checkNotification();

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((route) => {
                this.getTitle(route);
            });
    }

    private getTitle(route: any) {
        let path: string = route.url.split('?')[0];
        path = path.replaceAll("-", " ");
        let cleanedPath = path.replace(/^\/|\/$/g, '');
        cleanedPath = cleanedPath.replaceAll('/', ' - ');
        let pathSegments = cleanedPath.split(' ');
        let capitalizedSegments = pathSegments.map((segment: string) => segment.charAt(0).toUpperCase() + segment.slice(1));
        let title: string = capitalizedSegments.join(' ');
        title = title.replace("- View", ""),
            title = title.replace("- Edit", ""),
            this.title.setTitle("Truckin Central - " + title)
    }

    checkNotification() {
        this.contextService.companyId$.subscribe(async (val) => {
            if (val) {
                await this.notificationsService.updateNotifications();
                if (
                    this.notificationsService.getFirebaseNotificationPermission() ==
                    'default'
                ) {
                    // && Notification.permission == 'default' // This condition will prevent already granted notification but new login from registering
                    let dialog = this.dialog.open(
                        NotificationPermissionComponent,
                        {
                            disableClose: true,
                            width: '46.8rem',
                            position: { top: '6rem' },
                        }
                    );
                    let resultContract = await lastValueFrom(
                        dialog.afterClosed()
                    );
                    if (resultContract == 'allow') {
                        await this.notificationsService.getFirebaseToken();
                        if (
                            (Notification.permission as NotificationPermission) ==
                            'granted'
                        ) {
                            this.notificationsService.setFirebaseNotificationPermission(
                                'granted'
                            );
                            this.authService.registerNotificaiton();
                        } else if (
                            (Notification.permission as NotificationPermission) ==
                            'denied'
                        ) {
                            this.notificationsService.setFirebaseNotificationPermission(
                                'denied'
                            );
                        }
                    } else {
                        this.notificationsService.setFirebaseNotificationPermission(
                            'denied'
                        );
                    }
                }
            }
        });
    }

    isSafari() { return /^((?!chrome|android).)*safari/i.test(navigator.userAgent); }

    ngOnDestroy(): void {
        clearInterval(this.interval);
        if (this.routerSub) this.routerSub.unsubscribe();
        if (this.sideMenuSub) this.sideMenuSub.unsubscribe();
    }
}
