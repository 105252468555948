<div class="page-container">
    <div class="page-section">
        <div class="logo">
            <img src="assets/images/site-logo.svg">
        </div>
        <div class="body">
            <div class="auth-title">
                {{ "REVIEW_BACKGROUND_SCREENING_CANCELLATION_REQUEST" | translate }}
            </div>
            <div class="body-short-1">
                You are reviewing the cancellation request for Background Screening services from {{"CarrierName"}}.
                Please carefully evaluate this request before making your decision, as it will have a direct impact on
                their screening services.
                <br>
                <br>
                Once submitted, your decision cannot be reversed. Please confirm your choice carefully.
            </div>
            <div class="auth-body">
                <form class="mt-5" name="form" [formGroup]="form">
                    <div class="form-group">
                        <div class="form-check">
                            <input id="approve-cancelation" class="form-check-input" formControlName="cancelRequestStatus" type="radio"
                                value="Approve" />
                            <label class="form-check-label" for="approve-cancelation">
                                {{"APPROVE_CANCELLATION" |translate}}
                            </label>
                        </div>
                        <div class="form-check">
                            <input id="disapprove-cancelation" class="form-check-input" formControlName="cancelRequestStatus"
                                type="radio" value="Disapprove" />
                            <label class="form-check-label" for="disapprove-cancelation">
                                {{"DISAPPROVE_CANCELLATION" |translate}}
                            </label>
                        </div>
                    </div>
                    <div class="form-group" style="margin-top: 2rem;">
                        <button class="primary-button-large mt-1 w-100" (click)="submit()">
                            {{ "SUBMIT" | translate }}
                        </button>
                    </div>
                    <div class="row">
                        <div class="invalid-form-warning" *ngFor="let message of errorMessages">
                            <div class="invalid-icon"></div>
                            <div class="warning-message">
                                {{ message | translate }}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="footer">
            <div>
                © Truckin Central Inc. {{year}}
            </div>
            <div>
                <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i> info&#64;truckincentral.com</a>
            </div>
        </div>
    </div>
    <div class="image-section">
        <img src="assets/images/background-screening-cancellation-request.png">
    </div>
</div>