import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-auth-redirect',
    templateUrl: './auth-redirect.component.html',
    styleUrls: ['./auth-redirect.component.scss'],
})
export class AuthRedirectComponent implements OnInit {
    constructor(private route: ActivatedRoute) {}

    async ngOnInit(): Promise<void> {
        console.log(this.route.snapshot.queryParams);
        const authorizationCode = this.route.snapshot.queryParams['code'];
        // const tokens = await this.outgoingEmailService.exchangeCodeForToken(
        //     authorizationCode
        // );
        window.opener.postMessage({
            code: authorizationCode,
        });

        window.close();
    }
}
