<div class="container">
    <ng-container [ngSwitch]="pageShow">
        <ng-container *ngSwitchCase="PagesEnym.alreadyDone" [ngTemplateOutlet]="already_done">
        </ng-container>
        <ng-container *ngSwitchCase="PagesEnym.wrongAccount" [ngTemplateOutlet]="wrong_account">
        </ng-container>
        <ng-container *ngSwitchCase="PagesEnym.success" [ngTemplateOutlet]="success">
        </ng-container>
        <ng-container *ngSwitchCase="PagesEnym.successInvitaion" [ngTemplateOutlet]="success_invitaion">
        </ng-container>
        <ng-container *ngSwitchCase="PagesEnym.resetPassowrdLinkExpired"
            [ngTemplateOutlet]="reset_passowrd_link_expired">
        </ng-container>
        <ng-container *ngSwitchCase="PagesEnym.verifyEmailNoLongerValid"
            [ngTemplateOutlet]="verify_email_no_longer_valid">
        </ng-container>
        
        <ng-container *ngSwitchCase="PagesEnym.accountActivationLinkNoLongerValid"
            [ngTemplateOutlet]="accountActivationLinkNoLongerValid">
        </ng-container>
        
    </ng-container>

</div>
<div class="">
    <ng-container [ngSwitch]="pageShow">
        <ng-container *ngSwitchCase="PagesEnym.passwordChangedSuccessfully"
            [ngTemplateOutlet]="passwordChangedSuccessfully">
        </ng-container>
        <ng-container *ngSwitchCase="PagesEnym.resetPassowrdNoLongerValid"
            [ngTemplateOutlet]="reset_passowrd_no_longer_valid">
        </ng-container>
        <ng-container *ngSwitchCase="PagesEnym.link_expired"
            [ngTemplateOutlet]="link_expired">
        </ng-container>
        <ng-container *ngSwitchCase="PagesEnym.yourAccountHasBeenActivated"
            [ngTemplateOutlet]="yourAccountHasBeenActivated">
        </ng-container>
        <ng-container *ngSwitchCase="PagesEnym.passwordResetRequested" [ngTemplateOutlet]="password_reset_requested">
        </ng-container>
    </ng-container>

</div>

<ng-template #already_done>
    <div class="row w-100 main-content">
        <div class="col-md-6 col-12">
            <h2 class="heading-2">{{"YOU'VE_ALREADY_DONE_THIS" |translate}}</h2>
            <p class="body-short-2 desc">
                {{"YOU'VE_ALREADY_DONE_THIS_DESC" |translate}}
            </p>
            <div class="action-buttons">
                <button class="primary-button-large" routerLink="/auth/login">{{"CONTINUE" |translate}}</button>
            </div>
        </div>
        <div class="col-md-6 col-12 image-section">
            <img src="../../../assets/images/rafiki.svg">
        </div>
    </div>
</ng-template>

<ng-template #wrong_account>
    <div class="row w-100 main-content">
        <div class="col-md-6 col-12">
            <h2 class="heading-2">{{"OOPS_WRONG_ACCOUNT" |translate}}</h2>
            <p class="body-short-2 desc">
                {{"OOPS_WRONG_ACCOUNT_DESC" |translate : {currentEmail: currentEmail,newEmail: newEmail} }}
            </p>
            <div class="action-buttons">
                <button class="secondary-button-large logout-button" (click)="logout()"><span>{{"LOG_OUT"
                        |translate}}</span></button>
            </div>
        </div>
        <div class="col-md-6 col-12 image-section">
            <img src="../../../assets/images/amico.svg">
        </div>
    </div>
</ng-template>

<ng-template #success>
    <div class="row w-100 main-content">
        <div class="col-md-6 col-12">
            <h2 class="heading-2">{{"SUCCESS" |translate}}!</h2>
            <p class="body-short-2 desc">
                {{"SUCCESS_RESET_PASSOWRD" |translate}}
            </p>
            <div class="action-buttons">
                <button class="primary-button-large" routerLink="/home">{{"CONTINUE" |translate}}</button>
            </div>
        </div>
        <div class="col-md-6 col-12 image-section">
            <img src="../../../assets/images/rafiki.svg">
        </div>
    </div>
</ng-template>

<ng-template #success_invitaion>
    <div class="row w-100 main-content">
        <div class="col-md-6 col-12">
            <h2 class="heading-2">{{"SUCCESS" |translate}}!</h2>
            <p class="body-short-2 desc">
                {{"YOUR_EMAIL_HAS_BEEN_VERIFIED_SUCCESSFULLY_WELCOME_ABOARD" |translate}}
            </p>
            <div class="action-buttons">
                <button class="primary-button-large" routerLink="/home">{{"CONTINUE" |translate}}</button>
            </div>
        </div>
        <div class="col-md-6 col-12 image-section">
            <img src="../../../assets/images/rafiki.svg">
        </div>
    </div>
</ng-template>

<ng-template #reset_passowrd_link_expired>
    <div class="row w-100 main-content">
        <div class="col-md-6 col-12">
            <h2 class="heading-2">{{"RESET_PASSWORD_LINK_HAS_EXPIRED" |translate}}!</h2>
            <p class="body-short-2 desc">
                {{"PLEASE_TRY_RESETTING_YOUR_PASSWORD_AGAIN" |translate}}
            </p>
            <div class="action-buttons">
                <button class="primary-button-large" routerLink="/auth/forgot-password">{{"RESET_PASSWORD"
                    |translate}}</button>
            </div>
        </div>
        <div class="col-md-6 col-12 image-section amico-expired">
            <img src="../../../assets/images/amico-expired.png">
        </div>
    </div>
</ng-template>

<ng-template #reset_passowrd_no_longer_valid>
    <!-- <div class="row w-100 main-content">
        <div class="col-md-6 col-12">
            <h2 class="heading-2 title">{{"RESET_PASSWORD_LINK_NO_LONGER_VALID" |translate}}!</h2>
            <p class="body-short-2 desc">
                {{"PLEASE_TRY_RESETTING_YOUR_PASSWORD_AGAIN" |translate}}
            </p>
            <div class="action-buttons">
                <button class="primary-button-large" routerLink="/auth/forgot-password">{{"RESET_PASSWORD"
                    |translate}}</button>
            </div>
        </div>
        <div class="col-md-6 col-12 image-section amico-expired">
            <img src="../../../assets/images/amico-expired.png">
        </div>
    </div> -->
    <div class="page-container">
        <div class="page-section">
            <div class="logo">
                <img src="assets/images/site-logo.svg">
            </div>
            <div class="body">
                <div>
                    <div class="auth-body">
                        <div class="row w-100 main-content-new">
                            <h2 class="heading-4 title bold pl-0">{{"RESET_PASSWORD_LINK_NO_LONGER_VALID" |translate}}
                            </h2>
                            <p class="body-short-2 mt-2 pl-0">{{"PLEASE_TRY_RESETTING_YOUR_PASSWORD_AGAIN" |translate}}
                            </p>
                            <button class="primary-button-large w-100 mt-4"
                                routerLink="/auth/forgot-password">{{"RESET_PASSWORD"
                                |translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div>
                    © Truckin Central Inc. {{year}}
                </div>
                <div>
                    <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i>
                        info&#64;truckincentral.com</a>
                </div>
            </div>
        </div>
        <div class="image-section">
            <img src="assets/images/invalid.svg">
        </div>
    </div>
</ng-template>
<ng-template #link_expired>
    <div class="page-container">
        <div class="page-section">
            <div class="logo">
                <img src="assets/images/site-logo.svg">
            </div>
            <div class="body">
                <div>
                    <div class="auth-body">
                        <div class="row w-100 main-content-new">
                            <h2 class="heading-4 title bold pl-0">{{"LINK_EXPIRED" |translate}}
                            </h2>
                            <p class="body-short-2 mt-2 pl-0">The link you used has expired. Please request a new one to proceed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div>
                    © Truckin Central Inc. {{year}}
                </div>
                <div>
                    <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i>
                        info&#64;truckincentral.com</a>
                </div>
            </div>
        </div>
        <div class="image-section">
            <img src="assets/images/invalid.svg">
        </div>
    </div>
</ng-template>

<ng-template #verify_email_no_longer_valid>
    <div class="row w-100 main-content">
        <div class="col-md-6 col-12">
            <h2 class="heading-2 title">{{"VERIFY_EMAIL_LINK_NO_LONGER_VALID" |translate}}!</h2>
            <div class="action-buttons">
                <button class="primary-button-large" routerLink="/home">{{"HOME" |translate}}</button>
            </div>
        </div>
        <div class="col-md-6 col-12 image-section amico-expired">
            <img src="../../../assets/images/amico-expired.png">
        </div>
    </div>
</ng-template>

<ng-template #password_reset_requested>
    <div class="page-container">
        <div class="page-section">
            <div class="logo">
                <img src="assets/images/site-logo.svg">
            </div>
            <div class="body">
                <div>
                    <div class="auth-body">
                        <div class="row w-100 main-content-new">
                            <h2 class="heading-2 title bold">Email Sent!</h2>
                            <p class="body-short-2 mt-2">We have sent an email to {{newEmail}},<br> Please check your
                                inbox for a password reset link.</p>
                            <p class="body-short-2">Didn't receive the email?</p>
                            <ul class="mt-3" style="padding-left: 2.5rem;">
                                <li class="body-short-2">Check your spam or junk folder.</li>
                                <li class="body-short-2">Make sure you entered the correct email address.</li>
                                <li class="body-short-2">Wait a few minutes for the email to arrive.</li>
                            </ul>
                            <button class="primary-button-large w-100 mt-3" routerLink="/auth/login">{{"LOGIN"
                                |translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div>
                    © Truckin Central Inc. {{year}}
                </div>
                <div>
                    <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i>
                        info&#64;truckincentral.com</a>
                </div>
            </div>
        </div>
        <div class="image-section">
            <img src="assets/images/email-section.svg">
        </div>
    </div>
</ng-template>

<ng-template #passwordChangedSuccessfully>
    <div class="page-container">
        <div class="page-section">
            <div class="logo">
                <img src="assets/images/site-logo.svg">
            </div>
            <div class="body">
                <div>
                    <div class="auth-body">
                        <div class="row w-100 main-content-new">
                            <h2 class="heading-4 title bold pl-0">{{"SUCCESS" |translate}}!</h2>
                            <p class="body-short-2 mt-2 pl-0">{{"YOUR_PASSWORD_HAS_BEEN_UPDATED_AND_IS_SECURE"
                                |translate}}
                                <br> {{"YOU_CAN_NOW_LOG_IN_AGAIN" |translate}}
                            </p>
                            <button class="primary-button-large w-100 mt-4" routerLink="/auth/login">{{"LOG_IN"
                                |translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div>
                    © Truckin Central Inc. {{year}}
                </div>
                <div>
                    <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i>
                        info&#64;truckincentral.com</a>
                </div>
            </div>
        </div>
        <div class="image-section">
            <img src="assets/images/success.svg">
        </div>
    </div>
</ng-template>

<ng-template #yourAccountHasBeenActivated>
    <div class="page-container">
        <div class="page-section">
            <div class="logo">
                <img src="assets/images/site-logo.svg">
            </div>
            <div class="body">
                <div>
                    <div class="auth-body">
                        <div class="row w-100 main-content-new">
                            <h2 class="heading-4 title bold pl-0">{{"ACCOUNT_ACTIVATED" |translate}}!</h2>
                            <p class="body-short-2 mt-2 pl-0">{{"ACCOUNT_ACTIVATED_DESC" |translate}}</p>
                            <button class="primary-button-large w-100 mt-4" *ngIf="contextService.getCompanyId() == 0" routerLink="/auth/login">
                                {{"LOG_IN" |translate}}</button>
                            <button class="primary-button-large w-100 mt-4" *ngIf="contextService.getCompanyId() != 0" routerLink="/home">
                                {{"HOME" |translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div>
                    © Truckin Central Inc. {{year}}
                </div>
                <div>
                    <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i>
                        info&#64;truckincentral.com</a>
                </div>
            </div>
        </div>
        <div class="image-section">
            <img src="assets/images/account-activated.png">
        </div>
    </div>
</ng-template>

<ng-template #accountActivationLinkNoLongerValid>
    <div class="row w-100 main-content">
        <div class="col-md-6 col-12">
            <h2 class="heading-2 title">{{"ACCOUNT_ACTIVATION_LINK_NO_LONGER_VALID" |translate}}!</h2>
            <div class="action-buttons">
                <button class="secondary-button-large" (click)="contactSupport()">{{"CONTACT_SUPPORT"
                    |translate}}</button>
            </div>
        </div>
        <div class="col-md-6 col-12 image-section amico-expired">
            <img src="../../../assets/images/amico-expired.png">
        </div>
    </div>
</ng-template>