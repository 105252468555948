import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoleSysApiService } from 'src/app/modules/roles-and-permissions-system/services/role-sys-api.service';
import { SelectCompanyComponent } from 'src/app/shared/components/dialogs/select-company/select-company.component';
import { BillingSettingsApiService } from 'src/app/shared/services/billing-settings-api.service';
import { ContextService } from 'src/app/shared/services/context.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-payment-offer',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule
  ],
  templateUrl: './payment-offer.component.html',
  styleUrl: './payment-offer.component.scss'
})
export class PaymentOfferComponent implements OnInit {

    constructor(
        private router: Router,
        private billingSettingsApiService: BillingSettingsApiService,
        private spinner: NgxSpinnerService,
        public contextService: ContextService,
        public dialog: MatDialog,
        private permissionService: PermissionsService,
        private route: ActivatedRoute,
        private roleSysApiService: RoleSysApiService,
    ){}

    year: number = new Date().getFullYear();
    currentPeriodEndDate: Date | null = null;
    amount: number = 0;
    planIntervalCount: number = 1;
    errorMessages: Set<string> = new Set();
    showSelectCompanyDialog: boolean = false;
    isBusy: boolean = false;
    employeeCompanies: number = 0;

    ngOnInit(): void {
        this.getData();
        this.employeeCompanies = this.contextService.employeeCompanies$.getValue().length;
    }

    async getData(){
        try {
            this.errorMessages.clear();
            this.spinner.show();
            let response = await this.billingSettingsApiService.subscription();
            if(response.success && response.data) {
                this.currentPeriodEndDate = response.data.currentPeriodEndDate;
                this.amount = response.data.amount;
                this.planIntervalCount = response.data.planIntervalCount;
            }
        } catch (error: any) {
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        } finally {
            this.spinner.hide();
        }
    }

    goToPayment(){
        this.router.navigateByUrl('/payment');
    }

    async selectCompanyDialog() {
        let dialogRef = this.dialog.open(SelectCompanyComponent, {
            disableClose: true,
            autoFocus: false,
            width: '35rem',
            data: {
                closeButton: false,
            }
            // height: '90rem'
        });
        await lastValueFrom(dialogRef.afterClosed());
        await this.contextService.init();
        this.spinner.show();
        this.router.navigateByUrl('home');
        setTimeout(() => {
            this.spinner.hide();
        }, 3000);
    }

}
