<div class="page-container">
    <div class="page-section">
        <div class="logo">
            <img src="assets/images/site-logo.svg">
        </div>
        <div class="body">
            <h3 class="heading-3 bold">{{"FREE_TRIAL_ACTIVATED" |translate}}</h3>
            <p class="heading-5">
                Payment method saved successfully. Your free trial period will end on <span class="bold">{{trialEndDate |date}}</span>.
                You will be charged <span class="bold">{{amount| numberWithCommas}}</span> after the trial period.
            </p>
            <button class="primary-button-large mt-5" (click)="goToHome()">{{"GO_TO_DASHBOARD" |translate}}</button>
        </div>
        <div class="footer">
            <div>
                © Truckin Central Inc. {{year}}
            </div>
            <div>
                <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i> info&#64;truckincentral.com</a>
            </div>
        </div>
    </div>
    <div class="image-section">
        <img src="assets/images/free-trial-activated.png">
    </div>
</div>