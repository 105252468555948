<div class="page-container">
    <div class="page-section">
        <div class="logo">
            <!-- <img src="assets/images/site-logo.svg"> -->
        </div>
        <div class="body">
            <h3 class="heading-3">Maintenance in Progress</h3>
            <p class="body-short-1 mt-3">
                We are currently performing scheduled maintenance to improve our services. During this time, some features
            may be temporarily unavailable. We appreciate your patience and understanding. Please check back later, and
            thank you for your continued support!
            </p>
            <button class="primary-button-large mt-4" (click)="reload()">See if We're Back</button>
        </div>
        <div class="footer">
        </div>
    </div>
    <div class="image-section">
        <img src="assets/images/invalid.svg">
    </div>
</div>