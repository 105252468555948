<div class="welcome-container">
        <div class="welcome-section">
            <div class="logo">
                <img src="assets/images/site-logo.svg">
            </div>
            <div class="body">
                <h3 class="heading-3">{{"WELCOME_TO" |translate}} {{contextService.companyName$.getValue()}}!</h3>
                <p class="body-short-1">{{"WELCOME_TO" |translate}} {{contextService.companyName$.getValue()}}! {{"WELOCOME_PAGE_DESC" |translate}}</p>
                <button class="primary-button-large" (click)="proceed()">{{"PROCEED" |translate}}</button>
            </div>
            <div class="footer">
                <div>
                    © Truckin Central Inc. {{year}}
                </div>
                <div>
                    <a href="mailTo:info@truckincentral.com"> <i class="icon-mail-01"></i> info&#64;truckincentral.com</a>
                </div>
            </div>
        </div>
        <div class="image-section">
            <img src="assets/images/welcome-page.png">
        </div>
</div>